<template>
    <div class="az-body">
        <div class="az-signin-wrapper">
            <!-- az-card-signin -->
            <div class="az-card-signin">
                <img alt="" class="az-card-signin-logo" src="../../assets/logo-orange-full.png">
                <div class="az-signin-header">
                    <h3>Hi!</h3>
                    <h5 style="margin-bottom:25px;">Reset Your Password</h5>
                    <div>
                        <div class="form-group">
                            <label for="password">New Password</label>
                            <input :class="{'is-invalid': submitted && errors.has('password')}" class="form-control rounded" data-vv-as="Password" id="password" name="password" placeholder="Password"
                                   ref="password" type="password" v-model="credentials.password" v-validate="'required|min:8|max:35'">
                            <div class="invalid-feedback" v-if="submitted && errors.has('password')">{{ errors.first('password') }}</div>
                        </div><!-- form-group -->
                        <div class="form-group">
                            <label for="cnf_password">Confirm Password</label>
                            <input :class="{'is-invalid': submitted && errors.has('cnf_password')}" class="form-control rounded" data-vv-as="Confirm Password" id="cnf_password" name="cnf_password"
                                   placeholder="Password" type="password" v-model="credentials.cnf_password" v-validate="'required|confirmed:password'">
                            <div class="invalid-feedback" v-if="submitted && errors.has('cnf_password')">{{ errors.first('cnf_password') }}</div>
                        </div><!-- form-group -->
                        <button @click="forgotPassword" class="btn btn-az-secondary btn-block rounded">Reset Password</button>
                    </div>
                </div><!-- az-signin-header -->
                <div class="az-signin-footer">
                    <p>Do you want to login?
                        <router-link :to="{name: 'login'}">Click here</router-link>
                    </p>
                </div><!-- az-signin-footer -->
            </div>
        </div><!-- az-signin-wrapper -->
    </div>
</template>

<script>
    import EventBus from "@/event-bus";
    import myaxios from "axios";

    export default {
        name: "ResetPassword",
        props: ['token'],
        data() {
            return {
                submitted: false,
                loading: false,
                credentials: {
                    password: '',
                    cnf_password: ''
                },
                loggingIn: false,
                error: ''
            };
        },
        mounted() {
        },
        methods: {
            async forgotPassword() {
                this.submitted = true;
                let valid = await this.$validator.validate();
                if (valid) {
                    await this.resetPassword();
                }
            },
            async resetPassword() {
                try {
                    EventBus.$emit('openLoader');
                    await myaxios.patch(process.env.VUE_APP_ACCOUNTS_URL + `/accounts/user`, {
                        password: this.credentials.password
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': 'BWS ' + this.token
                        }
                    })
                    this.$toastr.s("You successfully reset the password! Now try login", 'Success');
                    await this.$router.push('/auth/login');
                } catch (e) {
                    this.$toastr.s("Password Reset Failed!", 'Error');
                } finally {
                    EventBus.$emit('closeLoader');
                }
            }
        }
    }
</script>

<style scoped>

</style>